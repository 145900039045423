<template>
  <div>
    <h1 class="title">{{ msg }}</h1>
    <div class="input-container">
      <input
        v-model="inputText"
        type="text"
        class="text"
        placeholder="请输入要查询的问题"
      />
      <button @click="submitQuestion1" class="btn">提交问题</button>
    </div>
    <h4 class="distext1">
      <pre style="font-weight: bold">总结回答:</pre>
      {{ msg1 }}
    </h4>
    <h4 class="distext2">
      <pre style="font-weight: bold">数据来源:</pre>
      {{ msg2 }}
    </h4>
  </div>
</template>

<script SETUP>
import axios from "axios";
import { onMounted, ref } from "vue";
// axios.defaults.baseURL = "http://localhost:5000";

export default {
  name: "HelloWorld",
  setup() {
    const msg = ref("BL智能助手");
    const msg1 = ref("");
    const msg2 = ref("");
    const inputText = ref("");

    async function fetchData1() {
      const response = await axios.get(`/api/chat?input=${inputText.value}`);
      msg1.value = response.data;

      const response1 = await axios.get(`/api/chat/page`);
      msg2.value = response1.data;
    }

    function submitQuestion1() {
      fetchData1();
    }

    onMounted(fetchData1);

    return {
      msg,
      msg1,
      msg2,
      inputText,
      submitQuestion1,
    };
  },
};
</script>

<style>
.title {
  color: #42b983;
  margin-bottom: 100px;
}

.btn {
  margin: 20px auto;
  padding: 10px;
  background-color: #afafaf;
  border-radius: 5px;
  width: 100px;
  height: 50px;
  font-size: 15px;
  border: none;
}

.text {
  background-color: #afafaf;
  border-radius: 5px;
  width: 800px;
  height: 50px;
  font-size: 15px;
  border: none;
  text-align: left;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.distext1 {
  color: #0c0f0e;
  font-weight: lighter;
  text-align: center;
  border: #afafaf;
  margin-top: 100px;
}
.distext2 {
  color: #0c0f0e;
  font-weight: lighter;
  text-align: center;
  border: #afafaf;
}
</style>
