<template>
  <div>
    <img class="img1" alt="BL logo" src="../assets/Baicleadeclogo.png">
    <img class="img2" alt="Vue logo" src="../assets/logo.png">

    <h1>{{ msg }}</h1>
    <BL_AI msg="BL智能助手"/>
  </div>
</template>

<script>
// @ is an alias to /src
import BL_AI from '@/components/BL_AI.vue'

export default {
  name: 'HomeView',
  components: {
    BL_AI
  }
}
</script>

<style>
.img1 {
  float: left;
  display: block;
  margin: 0 auto;
  width: 280px;
  height: 200px;
}

.img2 {
  display: block;
  margin: 0 auto;
  width: 250px;
  height: 200px;
  clear: both;
}

</style>
